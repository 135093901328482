import { graphql } from '@api/gql/gql'

export const getFacebookCredentials = graphql(`
    query getFacebookCredentials {
        facebook_credentials {
            oauth_token
            appsecret_proof
        }
    }
`)
