import { FC, useCallback } from 'react'
import { CoverType } from '@api/gql/graphql'
import { coverTypes, parseCoverSizes } from '@constants/album'
import { faTableCellsLarge } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/button'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownTrigger,
} from '@nextui-org/dropdown'
import { useIsMobile } from '@nextui-org/use-is-mobile'

import { CoverLayout } from './cover-layout'

type Props = {
  coverType: CoverType
  disabled?: boolean
  onChange: (value: CoverType) => void
}

export const AlbumCoverSelect: FC<Props> = (props) => {
  const isMobile = useIsMobile()

  const getCoverTypeName = useCallback((coverType: CoverType) => {
    const { rows, cols } = parseCoverSizes(coverType)
    return `${rows}x${cols}`
  }, [])

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button
          isDisabled={props.disabled}
          variant="bordered"
          size={isMobile ? 'sm' : 'md'}
          startContent={<FontAwesomeIcon icon={faTableCellsLarge} />}
        >
          {isMobile ? 'Cover' : 'Cover layout'}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        selectionMode="single"
        selectedKeys={[props.coverType]}
        onSelectionChange={(keys) => {
          if (!Array.from(keys).length) {
            return
          }

          props.onChange(Array.from(keys)[0] as CoverType)
        }}
        variant="faded"
        aria-label="Dropdown menu with description"
      >
        {coverTypes.map((coverType) => (
          <DropdownItem key={coverType} textValue={coverType}>
            <div className="flex items-center gap-x-2">
              <div className="flex flex-col justify-end w-[50px] h-[50px] md:w-[70px] md:h-[70px] bg-gray-100 border rounded-sm p-0.5">
                <CoverLayout coverType={coverType} />
              </div>
              <div className="w-4/12 text-sm md:text-base">{getCoverTypeName(coverType)}</div>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}
