import { FC } from 'react'
import { CoverType } from '@api/gql/graphql'

import { getCoverItemClass } from '../constants'

type Props = {
  coverType: CoverType
}

export const CoverItem: FC<Props> = (props) => {
  return <div className={getCoverItemClass(props.coverType)} />
}
