import { FC, ReactNode } from 'react'
import { AlbumPagePadding } from '@constants/album'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { clsx } from 'clsx'

type Props = {
  windowSizes: { width: number; height: number }
  windowSizesInner: { width: number; height: number }
  pagePadding: AlbumPagePadding
  children: ReactNode
  className?: string
  dev?: boolean
}

export const PageWrapper: FC<Props> = (props) => {
  const isMobile = useIsMobile()
  const {
    left, right, bottom, top,
  } = props.pagePadding

  return (
    <div
      style={{
        padding: `${top}px ${right}px ${bottom}px ${left}px`,
        width: isMobile ? '100%' : `${props.windowSizes.width}px`,
        height: isMobile ? undefined : `${props.windowSizes.height}px`,
      }}
      className={clsx(
        props.dev && 'border border-orange-500',
        props.className,
        isMobile && 'aspect-square',
      )}
    >
      <div
        className="relative"
        style={{
          width: `${props.windowSizesInner.width}px`,
          height: `${props.windowSizesInner.height}px`,
        }}
      >
        {props.children}
      </div>
    </div>
  )
}
