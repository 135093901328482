import {
  FC, useEffect, useMemo, useRef, useState,
} from 'react'
import { Album } from '@api/gql/graphql'
import {
  defaultCoverType,
  parseCoverSizes,
} from '@constants/album'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { Tooltip } from '@nextui-org/tooltip'
import { clsx } from 'clsx'

import { getCoverContainerClass, getCoverTypeClass } from '../constants'

import { AlbumCoverImage } from './album-cover-image'

type Props = {
  album: Album
  onChangeText?: (title: string, creatorName: string) => void
  editable?: boolean
}

export const AlbumCover: FC<Props> = (props) => {
  const { openModal } = useModal()

  const [title, setTitle] = useState<string>(props.album.title || '')
  const [creatorName, setCreatorName] = useState<string>(props.album.creator_name || '')

  const titleRef = useRef<HTMLDivElement | null>(null)
  const creatorNameRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!titleRef.current || !creatorNameRef.current) {
      return
    }

    titleRef.current.textContent = props.album.title || ''
    creatorNameRef.current.textContent = props.album.creator_name || ''
  }, [])

  const images = useMemo(() => {
    if (!props.album.pages?.length) {
      return []
    }

    const { count } = parseCoverSizes(props.album.cover_type || defaultCoverType)

    return (props.album.pages[0].images?.filter((image) => !image.deleted_at) ?? []).slice(0, count)
  }, [props.album])

  useEffect(() => {
    if (props.onChangeText) {
      props.onChangeText(title, creatorName)
    }
  }, [title, creatorName])

  const onOpenSelectAlbumCover = () => {
    openModal({
      type: ModalType.SELECT_ALBUM_COVER_MODAL,
      album_id: props.album.id,
    })
  }

  return (
    <div className="flex h-full flex-col justify-between">
      <div className={clsx(
        'flex flex-col h-full mb-2 md:mb-4',
        getCoverContainerClass(props.album.cover_type || defaultCoverType),
      )}
      >
        <div className="mb-3 md:mb-6">
          <div className="text-[4em] leading-none">
            <Tooltip isDisabled={!props.editable} content="Click on the text to edit it" placement="top-start">
              <input
                value={title}
                disabled={!props.editable}
                className="font-[500] p-0 w-full border bg-transparent border-transparent hover:border hover:border-primary"
                maxLength={20}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Tooltip>
          </div>

          <div className="text-[3em] leading-none">{props.album.subtitle}</div>
        </div>

        <div
          key={props.album.cover_type}
          className={clsx(
            'grid gap-1',
            getCoverTypeClass(props.album.cover_type || defaultCoverType),
          )}
        >
          {images.map((image) => (
            <AlbumCoverImage
              key={image.id}
              image={image}
              coverType={props.album.cover_type}
              onClick={onOpenSelectAlbumCover}
            />
          ))}
        </div>
      </div>

      <div className="flex items-end text-[1em] leading-none">
        <Tooltip isDisabled={!props.editable} content="Click on the text to edit it" placement="top-start">
          <input
            value={creatorName}
            disabled={!props.editable}
            className="p-0 w-full border bg-transparent border-transparent hover:border hover:border-primary"
            maxLength={80}
            onChange={(e) => setCreatorName(e.target.value)}
          />
        </Tooltip>
      </div>
    </div>
  )
}
