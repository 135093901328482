import { FC, useEffect, useState } from 'react'
import { Spinner } from '@nextui-org/spinner'
import { clsx } from 'clsx'

type Props = {
  delay?: number
  label?: string
  className?: string
}

export const SpinnerOverlay: FC<Props> = (props) => {
  const [show, setShow] = useState(!props.delay)

  useEffect(() => {
    if (props.delay) {
      const t = setTimeout(() => {
        clearTimeout(t)
        setShow(true)
      }, props.delay || 0)
    }
  }, [])

  return show && (
    <div className={clsx(
      'top-0 left-0 absolute w-full h-full flex flex-col bg-black/50 transition-all items-center justify-center',
      props.className,
    )}
    >
        {!!props.label && <div className="mb-2 text-white">{props.label}</div>}
      <Spinner />
    </div>
  )
}
