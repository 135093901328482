import {
  FC,
  useMemo,
} from 'react'
import { CoverType } from '@api/gql/graphql'
import {
  defaultCoverType,
  parseCoverSizes,
} from '@constants/album'
import { clsx } from 'clsx'

import {
  getCoverContainerClass,
  getCoverTypeClass,
} from '../constants'

import { CoverItem } from './cover-item'

type Props = {
  coverType: CoverType
}

export const CoverLayout: FC<Props> = (props) => {
  const items = useMemo(() => {
    const { count } = parseCoverSizes(props.coverType)

    return Array.from({ length: count }).map((_, index) => index)
  }, [props.coverType])

  return (
    <div className="p-0.5 flex h-full flex-col justify-between">
      <div className={clsx(
        'flex flex-col h-full',
        getCoverContainerClass(props.coverType || defaultCoverType),
      )}
      >
        <div className="mb-0.5 mt-0.5">
          <div className="bg-gray-300 w-8 h-1 mb-0.5" />
          <div className="bg-gray-300 w-3 h-1 mb-1" />
        </div>

        <div
          className={clsx(
            'grid gap-0.5',
            getCoverTypeClass(props.coverType),
          )}
        >
          {items.map((item) => (
            <CoverItem key={item} coverType={props.coverType} />
          ))}
        </div>

        <div className="h-2 mt-1 flex items-end mb-0.5">
          <div className="bg-gray-300 w-8 h-1" />
        </div>
      </div>

    </div>
  )
}
