import { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export const useDebouncedLoading = (loading: boolean, delay: number = 1000) => {
  const [value, setValue] = useState<boolean>(loading)

  const setDebounced = useDebouncedCallback(setValue, delay)

  useEffect(() => {
    setDebounced(loading)
  }, [loading])

  return value
}
