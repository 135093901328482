import { FC, useState } from 'react'
import { AlbumProcessingStatus } from '@api/gql/graphql'
import { useMutation, useSubscription } from '@apollo/client'
import { toast } from '@components/toast'
import { Button } from '@nextui-org/button'

import { deleteAlbumDuplicates, subscriptionAlbumProcessingStatus } from '../api'

type Props = {
  albumId: string;
  onRefresh: () => void
};

export const AlbumDuplicatesButton: FC<Props> = (props) => {
  const [
    deleteAlbumDuplicatesMutation,
    deleteAlbumDuplicatesState,
  ] = useMutation(deleteAlbumDuplicates, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
    onCompleted: () => {
      toast.success('All duplicates have been deleted')
    },
  })

  const [currentStatus, setCurrentStatus] = useState<AlbumProcessingStatus>(
    AlbumProcessingStatus.NotExecuted,
  )

  useSubscription(subscriptionAlbumProcessingStatus, {
    variables: { albumId: props.albumId },
    onData: (res) => {
      const albumProcessingStatus = res.data?.data?.album_processing_status
          || AlbumProcessingStatus.NotExecuted

      // Обновляем состояние альбома если задача завершена
      if (currentStatus === AlbumProcessingStatus.Searching
          && albumProcessingStatus === AlbumProcessingStatus.Completed) {
        props.onRefresh()
      }

      setCurrentStatus(albumProcessingStatus)
    },
  })

  const onDeleteDuplicates = () => {
    deleteAlbumDuplicatesMutation({
      variables: { albumId: props.albumId },
    })
  }

  const isLoading = currentStatus === AlbumProcessingStatus.Searching
      || deleteAlbumDuplicatesState.loading

  if (currentStatus === AlbumProcessingStatus.NotExecuted) {
    return <></>
  }

  return (
    <Button
      variant="bordered"
      isLoading={isLoading}
      onClick={onDeleteDuplicates}
    >
      {currentStatus === AlbumProcessingStatus.Searching && 'Search for duplicates...'}
      {currentStatus === AlbumProcessingStatus.Completed && 'Remove duplicate images'}
    </Button>
  )
}
