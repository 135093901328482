import { graphql } from '@api/gql/gql'

export const updateImagesUrls = graphql(`
    mutation updateImagesUrls($orderId: ID!) {
        update_images_urls(order_id: $orderId) {
            id
            albums {
                id
                updated_at
                pages {
                    id
                    images {
                        id
                        image_small_url
                        image_medium_url
                        image_large_url
                    }
                }
            }
        }
    }
`)
