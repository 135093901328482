import { CSSProperties, FC, useMemo } from 'react'
import { AlbumImage, ImageSize } from '@api/gql/graphql'
import { Image } from '@components/image'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { clsx } from 'clsx'

type Props = {
  image: AlbumImage
  pageWidth: number
  pageHeight: number
  onRemove?: (albumImageId: string) => void
  editable?: boolean
  control?: boolean
  windowWidth: number
  windowHeight: number
  imageSize: ImageSize
  debug?: boolean
}

export const AlbumPicture: FC<Props> = (props) => {
  const isMobile = useIsMobile()

  const getPercent = () => {
    if (props.pageWidth > props.pageHeight) {
      return (props.windowWidth / props.pageWidth) * 100
    }

    return (props.windowHeight / props.pageHeight) * 100
  }

  const resize = (num: number): number => {
    const PERCENT = 100 - getPercent()
    return Math.floor(num * (1 - PERCENT / 100))
  }

  const getOffsetPositionY = () => {
    if (props.pageWidth > props.pageHeight) {
      const height = resize(props.pageHeight || 0)
      return (props.windowHeight - height) / 2
    }

    return 0
  }

  const getOffsetPositionX = () => {
    if (props.pageWidth < props.pageHeight) {
      const width = resize(props.pageWidth || 0)
      return (props.windowWidth - width) / 2
    }

    return 0
  }

  const imageUrl = useMemo(() => {
    switch (props.imageSize) {
      case ImageSize.Small:
        return props.image.image_small_url
      case ImageSize.Large:
        return props.image.image_large_url
      case ImageSize.Medium:
        return props.image.image_medium_url
      default:
        return props.image.image_medium_url
    }
  }, [props.image])

  const imgStyle = useMemo((): CSSProperties => ({
    width: resize(props.image.width || 0),
    height: resize(props.image.height || 0),
    top: resize(props.image.position_y || 0) + getOffsetPositionY(),
    left: resize(props.image.position_x || 0) + getOffsetPositionX(),
  }), [props.image])

  const onRemove = () => {
    if (!props.image.id) {
      return
    }

    if (props.onRemove) {
      amplitude.event({
        type: AmplitudeEvent.AlbumDeleteImage,
        image_id: props.image.id,
      })

      props.onRemove(props.image.id)
    }
  }

  return (
    <div
      key={props.image.external_id}
      data-id={props.image.id}
      data-external-id={props.image.external_id}
      className={clsx(
        'absolute border-[1px] md:border-[2px] border-white box-border',
        props.image.duplicate && 'opacity-40',
      )}
      style={imgStyle}
    >
      <Image
        src={imageUrl ?? ''}
        alt=""
        className="object-cover"
        style={imgStyle}
        debug={props.debug}
      />

      {props.control && (
        <Button
          variant="flat"
          size={isMobile ? 'sm' : 'md'}
          isIconOnly
          isDisabled={!props.editable}
          className="top-2 right-2 absolute bg-black/20"
          startContent={<FontAwesomeIcon icon={faTrash} className="text-white" />}
          onClick={onRemove}
        />
      )}
    </div>
  )
}
