import {
  FC, ReactNode, useEffect, useMemo, useRef, useState,
} from 'react'
import {
  Album,
  CoverType,
} from '@api/gql/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, AlertType } from '@components/alert'
import { toast } from '@components/toast'
import { defaultCoverType, defaultPageFormat } from '@constants/album'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useApolloError } from '@helpers/apollo-error'
import { useDebouncedLoading } from '@helpers/use-debounced-loading'
import { Button } from '@nextui-org/button'
import { Spinner } from '@nextui-org/spinner'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { WindowSizes } from '@share/album/types'
import chunk from 'lodash/chunk'
import concat from 'lodash/concat'
import slice from 'lodash/slice'

import { updateImagesUrls } from './api/update-images-urls'
import { getAlbum, restoreAlbumImages, updateAlbum } from './api'
import {
  AlbumCoverSelect, AlbumDuplicatesButton,
  SpreadPageItem,
} from './components'

type Props = {
  albumId: string
  editable: boolean
  children?: (count: number) => ReactNode
}

export const AlbumEditor: FC<Props> = (props) => {
  const [updateImagesUrlsMutation, updateImagesUrlsState] = useMutation(updateImagesUrls)
  const [restoreAlbumImagesMutation, restoreAlbumImagesState] = useMutation(restoreAlbumImages, {
    onCompleted: () => {
      toast.success('All deleted images have been restored')
    },
  })

  const isMobile = useIsMobile()
  const [album, setAlbum] = useState<Album>({ id: '' })

  const getAlbumState = useQuery(getAlbum, {
    fetchPolicy: 'cache-and-network',
    variables: { id: props.albumId as string },
    onCompleted: (res) => {
      if (!res.album?.order_id) {
        return
      }

      updateImagesUrlsMutation({
        variables: { orderId: res.album.order_id },
      })
    },
  })

  useEffect(() => {
    if (!getAlbumState.data?.album) {
      return
    }

    setAlbum(getAlbumState.data?.album)
  }, [getAlbumState.data?.album?.updated_at])

  const [updateAlbumMutation, updateAlbumState] = useMutation(updateAlbum)

  const pageError = useApolloError([
    getAlbumState.error,
  ])

  const pages = album?.pages ?? []

  // Активные страницы
  const pagesLive = useMemo(
    () => pages
      .map((page) => ({
        ...page,
        images: page.images?.filter((image) => !image.deleted_at) ?? [],
      }))
      .filter((page) => {
        return !!page.images?.length
      }),
    [pages],
  )

  const firstPageSort = useMemo(() => {
    if (!pagesLive.length) {
      return null
    }
    return pagesLive[0].sort ?? -1
  }, [pagesLive])

  const lastPageSort = useMemo(() => {
    if (!pagesLive.length) {
      return null
    }
    return pagesLive[pagesLive.length - 1].sort ?? -1
  }, [pagesLive])

  const spreads = useMemo(() => {
    if (!pagesLive.length) {
      return []
    }

    let items = [...pagesLive].slice(1, pagesLive.length)

    // Добавочная страница
    if (pagesLive.length % 2 !== 0) {
      items = concat(
        slice(items, 0, items.length - 1),
        { id: '-1', images: [] },
        slice(items, items.length - 1),
      )
    }

    // Обложку делаем на пол разворота
    return [[pagesLive[0]], ...chunk(items, 2)]
  }, [pagesLive])

  const onUpdateCoverType = (coverType: CoverType) => {
    amplitude.event({
      type: AmplitudeEvent.AlbumSelectCoverLayout,
      cover_type: coverType,
    })

    updateAlbumMutation({
      variables: {
        input: {
          id: album.id,
          title: album.title || '',
          creator_name: album.creator_name || '',
          cover_type: coverType,
          page_format: album.page_format || defaultPageFormat,
        },
      },
    })
  }

  const [rootWidth, setRootWidth] = useState<number>(0)
  const rootRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setRootWidth(rootRef.current?.clientWidth ?? 0)
  }, [])

  const windowSizes = useMemo((): WindowSizes => {
    const padding = isMobile ? 4 : 10
    return {
      outer: {
        width: rootWidth / 2,
        height: rootWidth / 2,
      },
      inner: {
        width: (rootWidth / 2) - (padding * 2),
        height: (rootWidth / 2) - (padding * 2),
      },
      padding: {
        left: padding,
        right: padding,
        bottom: padding,
        top: padding,
      },
    }
  }, [rootWidth])

  const updateImagesUrlsLoading = useDebouncedLoading(updateImagesUrlsState.loading, 1000)

  const onRestoreImages = () => {
    restoreAlbumImagesMutation({ variables: { albumId: props.albumId } })
  }

  return (
    <div className="flex flex-col gap-y-2 md:gap-y-5 relative" ref={rootRef}>
      <Alert type={AlertType.error} message={pageError} />

      <div className="flex items-center gap-x-2">
        <AlbumCoverSelect
          disabled={updateAlbumState.loading || !props.editable}
          coverType={album.cover_type || defaultCoverType}
          onChange={onUpdateCoverType}
        />

        <Button
          onClick={onRestoreImages}
          variant="bordered"
          startContent={<FontAwesomeIcon icon={faUndo} />}
          isLoading={restoreAlbumImagesState.loading}
          isDisabled={!props.editable}
          size={isMobile ? 'sm' : 'md'}
        >
          Restore all images
        </Button>

        <AlbumDuplicatesButton
          albumId={props.albumId}
          onRefresh={() => {
            getAlbumState.refetch()
          }}
        />

        {updateImagesUrlsLoading && (
          <div className="flex items-center text-gray-500">
            <Spinner size="sm" className="mr-2" />
            Updating links to photos...
          </div>
        )}
      </div>

      {getAlbumState.loading ? (
        <Spinner />
      ) : (
        <>
          {spreads.map((spreadPages, spreadIndex) => (
            <div className="flex flex-row" key={spreadIndex}>
              {spreadPages.map((page, positionIndex) => (
                <SpreadPageItem
                  key={page.id}
                  album={album}
                  setAlbum={setAlbum}
                  windowSizes={windowSizes}
                  spreadPage={page}
                  editable={!updateImagesUrlsState.loading && props.editable}
                  positionIndex={positionIndex}
                  firstPageSort={firstPageSort}
                  lastPageSort={lastPageSort}
                />
              ))}
            </div>
          ))}
        </>
      )}

      {props.children ? props.children(pagesLive.length) : <></>}
    </div>
  )
}
