import { SvgHeart } from '@components/icons'

export const AlbumBackCover = () => {
  return (
    <div className="flex h-full flex-col justify-end">
      <div className="text-[0.8em] text-gray-500 flex gap-x-1">
        with
        {' '}
        <SvgHeart className="w-[1.1em] h-[1.1em]" />
        {' '}
        from
        {' '}
        easyphotobook.me
      </div>
    </div>
  )
}
