import { FC } from 'react'
import { Album, AlbumPage, ImageSize } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { SpinnerOverlay } from '@components/spinner-overlay'
import { defaultCoverType, defaultPageFormat } from '@constants/album'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { useDebouncedValue } from '@helpers/use-debounced-value'
import {
  AlbumBackCover,
  AlbumCover,
  AlbumPicture,
  PageWrapper,
} from '@share/album'
import { clsx } from 'clsx'

import { deleteAlbumImage, updateAlbum } from '../api'
import { WindowSizes } from '../types'

type Props = {
  album: Album
  setAlbum: (value: Album) => void
  windowSizes: WindowSizes
  spreadPage: AlbumPage
  positionIndex: number
  firstPageSort: number | null
  lastPageSort: number | null
  editable?: boolean
}

export const SpreadPageItem: FC<Props> = (props) => {
  const [updateAlbumMutation, updateAlbumState] = useMutation(updateAlbum)
  const [deleteAlbumImageMutation, deleteAlbumImageState] = useMutation(deleteAlbumImage)

  const isLoading = updateAlbumState.loading || deleteAlbumImageState.loading

  // Удаление фотографии и создание нового коллажа
  const onRemove = async (albumImageId: string) => {
    const deleteAlbumImageResponse = await deleteAlbumImageMutation({
      variables: {
        albumImageId,
      },
    })

    const albumPage = deleteAlbumImageResponse.data?.delete_album_image
    if (!albumPage) {
      return
    }

    // Заменяем нужную страницу альбома в локальном состоянии
    // Обновление всего альбома занимает время поэтому придумал такую хитрость
    props.setAlbum({
      ...props.album,
      pages: props.album?.pages?.map((page) => {
        if (page.id === albumPage.id) {
          return { ...page, ...albumPage }
        }

        return page
      }) ?? [],
    })
  }

  const [, setAlbumText] = useDebouncedValue<Pick<Album, 'id' | 'title' | 'creator_name'>>(
    {
      id: props.album.id,
      title: props.album.title,
      creator_name: props.album.creator_name,
    },
    async ({ id, title, creator_name }) => {
      if (props.album.id !== id) {
        return
      }

      // Остановка обновления если нет изменений
      if (props.album.title === title && props.album.creator_name === creator_name) {
        return
      }

      await updateAlbumMutation({
        variables: {
          input: {
            id: props.album.id,
            title: title || '',
            creator_name: creator_name || '',
            cover_type: props.album.cover_type || defaultCoverType,
            page_format: props.album.page_format || defaultPageFormat,
          },
        },
      })

      amplitude.event({
        type: AmplitudeEvent.AlbumUpdate,
        title: title ?? '',
        creator_name: creator_name ?? '',
      })
    },
    1000,
  )

  const isLeft = props.positionIndex === 0
  const isRight = props.positionIndex === 1

  const isContent = props.firstPageSort !== props.spreadPage.sort
        && props.lastPageSort !== props.spreadPage.sort

  return (
    <div className="relative" data-id={props.spreadPage.id}>
      <PageWrapper
        windowSizes={props.windowSizes.outer}
        windowSizesInner={props.windowSizes.inner}
        pagePadding={props.windowSizes.padding}
        className={clsx(
          'relative shadow-medium text-[0.26em] sm:text-[0.53em] md:text-[0.6em] lg:text-[0.8em]',
          isLeft && 'rounded-l-md',
          isRight && 'rounded-r-md',
        )}
      >
        {props.firstPageSort === props.spreadPage.sort && (
        <AlbumCover
          album={props.album}
          editable={props.editable}
          onChangeText={(title, creatorName) => {
            setAlbumText({
              id: props.album.id,
              title,
              creator_name: creatorName,
            })
          }}
        />
        )}

        {isContent && (
        <>
          {props.spreadPage?.images?.map((image) => (
            <AlbumPicture
              key={image.external_id}
              image={image}
              control={props.editable}
              editable={props.editable}
              pageWidth={props.spreadPage.width || 0}
              pageHeight={props.spreadPage.height || 0}
              windowWidth={props.windowSizes.inner.width}
              windowHeight={props.windowSizes.inner.height}
              onRemove={onRemove}
              imageSize={ImageSize.Medium}
            />
          ))}
        </>
        )}

        {props.lastPageSort === props.spreadPage.sort && <AlbumBackCover />}
      </PageWrapper>

      {isLoading && (
        <SpinnerOverlay
          className="rounded-md"
          label="Recreating..."
          delay={150}
        />
      )}
    </div>
  )
}
